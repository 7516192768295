<template>
  <div class="col-2 sidebar">
    <v-navigation-drawer permanent class="tabs-sidebar min-vh-10" width="17%">
      <!-- <v-list-item>overlay-opacity="1" overlay-color="red" expand-on-hover
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            RULESMANAGER 1.0
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>-->

      <v-divider></v-divider>
      <!-- <router-link class="p-20 v-list-item__title sidebar-links text-white" :to="item.tolink"> -->
      <v-tabs vertical dense nav>
        <v-tab
          v-for="item in items"
          :key="item.title"
          link
          :to="item.tolink"
          class="text-decoration-none fs-20"
        >
          <v-icon color="white" left>
            {{ item.icon }}
          </v-icon>
          <small>{{ item.title }}</small>
        </v-tab>
      </v-tabs>
      <!-- </router-link> -->
    </v-navigation-drawer>
  </div>
</template>
<!-- <template>
  <v-card class="tabs-sidebar mt-8 min-vh-100">
    <v-toolbar flat color="primary" dark class="my-5 mt-8 pt-2">
      <v-spacer></v-spacer>
      <v-toolbar-title>Fraud Detection Platform<br /><small>{{
                  tenantName
              }}</small></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-tabs vertical v-model="activeTab">
      <v-tab v-for="item in items" :key="item.title" @click="clickTab(item)">
        <v-icon color="white" left>
          {{ item.icon }}
        </v-icon>
        {{ item.title }}
      </v-tab>
    </v-tabs>
  </v-card>
</template> -->

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      items: [
        {
          title: 'Dashboard',
          icon: 'poll',
          tolink: { name: 'RulesDashboard', params: { type: 'all' } },
        },
        {
          title: 'Organization Config',
          icon: 'bookmark',
          tolink: { name: 'SpreadingDashboard', params: { type: 'all' } },
        },
        /*{
          title: "My Rules",
          icon: "bookmark",
          tolink: { name: "RulesDashboard", params: { type: "me" } },
        },
        {
          title: "Approval",
          icon: "mdi-book-check",
          tolink: { name: "RulesDashboard", params: { type: "approve" } },
        },*/
      ],
    };
  },
  methods: {},
  created() {
    if (localStorage.roles) {
      if (localStorage.roles.includes('approver')) {
        this.items.push({
          title: 'Approval',
          icon: 'mdi-book-check',
          tolink: { name: 'RulesDashboard', params: { type: 'approve' } },
        });
        this.items.push({
          title: 'Org Config Approval',
          icon: 'mdi-book-check',
          tolink: { name: 'SpreadingDashboard', params: { type: 'approve' } },
        });
      }
      if (localStorage.roles.includes('admin')) {
        this.items.push({
          title: 'Add/Update User',
          icon: 'mdi-account-cog',
          tolink: { name: 'AddEditUser' },
        });
        this.items.push({
          title: 'Add/Update Tenant',
          icon: 'mdi-bank-outline',
          tolink: { name: 'AddEditTenant' },
        });
      }
      this.items.push({
        title: 'View Docs Logs',
        icon: 'mdi-math-log',
        tolink: { name: 'ViewDocLogs' },
      });
    }
  },
};
</script>
<style scoped lang="scss">
// .heading {
//   color: $primary-text-color;
// }
// .v-application--is-ltr .v-list-item__icon:first-child {
//   margin-right: 0px;
// }
// .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
//   margin-left: 0px;
// }
</style>
