import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import EditRule from '../views/EditRule'
import AddConfig from '../views/AddConfig'
import ViewConfig from '../views/ViewConfig'
import EditConfig from '../views/EditConfig'
import AddRule from '../views/AddRule'
import ViewRule from '../views/ViewRule'
import RulesDashboard from '../views/RulesDashboard'
import SpreadingDashboard from '../views/SpreadingDashboard'
import NotFound from '../views/NotFound'
import AddEditUser from '../views/AddEditUser'
import AddEditTenant from '../views/AddEditTenant'
import DynamicUI from '../views/DynamicUI'
import ViewDocLogs from '../views/ViewDocLogs'
import ViewLog from '../views/ViewLog'

Vue.use(VueRouter)
const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/login',
        name: 'Login',
        component: Home,
    },
    {
        path: '/rulesdashboard/:type',
        name: 'RulesDashboard',
        component: RulesDashboard,
        props: true
    },
    {
        path: '/spreadingdashboard/:type',
        name: 'SpreadingDashboard',
        component: SpreadingDashboard,
        props: true
    },
    {
        path: '/spreadconfig/:id',
        name: 'EditConfig',
        component: EditConfig,
        props: true
    },
    {
        path: '/spreadconfig',
        name: 'AddConfig',
        component: AddConfig,
        props: true
    },
    {
        path: '/viewConfig/:id',
        name: 'ViewConfig',
        component: ViewConfig,
        props: true
    },
    {
        path: '/rule/:id',
        name: 'EditRule',
        component: EditRule,
        props: true
    },
    {
        path: '/rule',
        name: 'AddRule',
        component: AddRule,
        props: true
    },
    {
        path: '/view/:id',
        name: 'ViewRule',
        component: ViewRule,
        props: true
    }, {
        path: '/user',
        name: 'AddEditUser',
        component: AddEditUser
    }, {
        path: '/tenant',
        name: 'AddEditTenant',
        component: AddEditTenant
    }, {
        path: '/product',
        name: 'DynamicUI',
        component: DynamicUI
    },
    {
        path: '/viewDocLogs',
        name: 'ViewDocLogs',
        component: ViewDocLogs,
        props: true,
    },
    {
        path: '/viewLog/:id',
        name: 'ViewLog',
        component: ViewLog,
        props: true
    },
    {
        // catch all 404 - define at the very end
        path: "*",
        name: 'NotFound',
        component: NotFound
    }
]
const router = new VueRouter({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router